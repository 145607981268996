import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../styles/colors';
import { FieldWrapper } from '../FieldWrapper';

export const TextInput = ({ name, noMargin, defaultValue, tips, onChange, ...rest }: TextInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <FieldWrapper noMargin={noMargin} tips={tips}>
          {/* <pre>{JSON.stringify({ fieldState }, null, 2)}</pre> */}
          <TextFieldExtended
            {...field}
            fullWidth
            {...rest}
            onChange={(event) => {
              field.onChange(event);
              if (onChange) {
                onChange(event);
              }
            }}
            helperText={fieldState.error ? <ErrorMessage>{fieldState.error.message}</ErrorMessage> : ''}
            color={fieldState.error ? 'error' : 'primary'}
          />
        </FieldWrapper>
      )}
    />
  );
};

const ErrorMessage = styled.div`
  padding: 0 0 0 8px;
  font-size: 16px;
  color: ${Colors.Error};
`;

type TextInputProps = {
  tips?: React.ReactNode;
  name: string;
  noMargin?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & TextFieldProps;

const TextFieldExtended = styled(TextField)`
  .MuiInputBase-colorError {
    color: ${Colors.Error};
  }

  .Mui-focused {
    fieldset {
      border-left-width: 6px !important; // override inline-style
    }
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
`;
